.BasicForm-container{
    display: flex;
    flex-direction: row;
    height:100%;
    background-color: #ecf0f1;
    margin:0.5rem;
}
.formsideb{
    width:auto;
    padding:2rem;
    overflow-y: scroll;
}

@media  screen and (max-width:750px) {
    .formdesc{
        display:none;
    }
    .formside{
        width:100%;
    }
    
}