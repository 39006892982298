.flipping {
   position: relative;
   width: 300px;
   height: 300px;
   animation: spin3D 3s ease-in-out infinite;
   perspective: 168px;
}
@keyframes spin3D {
   from { transform: rotateY(0deg) }
   to { transform: rotateY(360deg) }
 }
.Spinner-text{
   color:#2c3e50;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.Spinnerlogo{
   width:300px;
   height:300px;
   border-radius: 50%;
}
.Spinner-parent{
   height:100%;
   width:100%;
   box-sizing: border-box;
   border-top-left-radius: 10px;
   border-top-right-radius: 10px;
   display: flex;
   background-color: #ecf0f1;
   align-items: center;
   justify-content: center;
}

.flipping:before {
   content: '';
   display: block;
   width: 44.8px;
   height: 44.8px;
   position: absolute;
   animation: flipping-ew9517 1s infinite;
   background-image: url("/public/DescribeITlogo.jpeg");
   transform-origin: bottom;
}

