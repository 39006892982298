.AdvancedForm-container{
    display: flex;
    flex-direction: row;
    height:100%;
    background-color: #ecf0f1;

}
.formdesc{
    box-sizing: border-box;
    padding:2rem;
    width:auto;
    height:100%;
    text-align: justify;
}
.formside{
    width:auto;
    margin:0.5rem;
    overflow-y: scroll;
    padding:2rem;
}
.Advanced-form-title{
    color:#FB8122;
}
.Advanced-form-desct{
    color:#9DAAF2;
    font-family: Poppins,Verdana;
}
.Advanced-form-descp{
    color:#FB8122;
    font-family: Roboto,sans-serif;
}
.main{
    color:#2c3e50;
    font-family: Poppins,Verdana, Geneva, Tahoma, sans-serif;
    font-size:13px;
}
.option{
    color:  #9DAAF2;
}
input{
    margin-left:1.5rem;
}
select{
    width:80%;
    height:1.6rem;
   /*  border: #9DAAF2 solid 5px; */
    color: gray;
    font-family: Roboto,Poppins,Verdana;
    margin:1rem;
}
.adtextarea{
    resize: none;
    width:80%;
    height:5rem;
   /*  border: #9DAAF2 solid 5px; */
    margin:1rem;
}
.textboxs{
    width:75%;
    height:1.5rem;
    border-radius: 3px;
    margin:1rem;
    /* border: #9DAAF2 solid 5px; */
}
.submit-btn{
    width:10rem;
  height:2.5rem;
  color: white;
  background-color: #2c3e50;
  border-color: #9DAAF2;
  border-width: 3px;
  border-radius: 3px;
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  margin-left: auto;
  margin-right: auto;
}
.submitdiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
i{
    color:white;
    position: absolute;
    right:30px;
}
@media  screen and (max-height:500px) {
    .formdesc{
        display:none;
    }
}
@media  screen and (max-width:750px) {
    .formdesc{
        display:none;
    }
    .formside{
        width:100%;
    }
    
}

