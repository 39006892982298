


.navbar-container {
    display: flex;
    background: white;
    height:4.5rem;
    justify-content: flex-start; /* Distribute items along the main axis with maximum space between them */
    align-items: center; /* Center items vertically */
    padding-top:0.5rem;
    padding-bottom:0.5rem;
  }
  
  .navbar-header {
    display: flex;
    align-items: flex-start; 
    justify-content: flex-start;
    margin-top:-1rem;
    margin-left:-3rem;
  }

  .itnav{
    font-size: 25px;
    background-color: rgb(235, 11, 216);
    padding:0.3rem;
    border-radius: 6px;
    transform: rotateX('5deg');
    font-family: Roboto,Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .logo {
    border-radius: 50px;
    width:40%;
    height:10%;
    border-radius: 0.5rem;
  }
  
  .navbar-title {
    margin: 0;
    color:white;
    text-align:center;
    text-decoration: underline dotted yellowgreen;
    font-size: 20px;
  }
  