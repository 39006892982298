.userdetail-container{
    background-color: #ecf0f1;
    padding:3rem;
    display: flex;
    border-radius: 5px;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-direction: column;
}
.ud-parent{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:3px;
    justify-content: space-between;
}
.ud-text{
    color:#2c3e50;
    font-family: Poppins;
}
.ud-btn{
    width:98%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.firstname{
    flex: 1 0 auto;
    margin-right: 10px;
    height:2rem;
    padding-left:10px;
}
.lastname{
    flex:1 0 auto;
    padding-left:10px;
}
.udclose{
    position: absolute;
    top:0;
    color:black;
    right:0;
}
.namebox{
    margin:0px;
    
    height:2rem;
    border-radius: 3px;
}
.emailbox{
    margin:0px;
    height:2rem;
    border-radius: 3px;
    flex:1 0 97%;
    padding-left:10px;
}
.send-btn{
    width:15rem;
    height:3rem;
    color: white;
    background-color: #2c3e50;
    border-color: #9DAAF2;
    border-width: 4px;
    border-radius: 3px;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    margin-top:0.5rem;
}
.names{
    display: flex;
    align-items: center;
    width:85%;
    gap:5px;

}
.email-div{
    width:85%;
    margin-top:0.5rem;
    box-sizing: border-box;
    background-color: black;

}