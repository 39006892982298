.footer-container{
    display: flex;
    width:100%;
    align-items: center;
    justify-content: center;
   
    
}
.copyright-footer{
    padding:0.2rem;
    margin:0.2rem;
    display: block;
    margin:1rem;
    top:0px;
}