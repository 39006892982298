.user-content{
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  gap:3px;
  width:100%;
  height:92vh;
  margin-top:-1rem;
}
.copyright{
  position: absolute;
  bottom: 0;
  color:white;

}
.star{
  color:red;
}
.blur{
  width:100vw;
  height:112vh;
  backdrop-filter: blur(7px) saturate(169%);
  -webkit-backdrop-filter: blur(7px) saturate(169%);
  background-color: rgba(42, 48, 59, 0.53);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
 position:absolute;
 display: flex;
 align-items: center;
justify-content: center;
 z-index: 1001;
 top:0;
 left:0;
}

.btn-div{
  background-color: #ecf0f1;
  padding: 0.7rem;
  width:98%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.user-nav{
  background-color: #ecf0f1;
  width:30vw;
  margin:0px;
  height:100%;
  border-radius: 15px;
}
.Navi-gen-btn{
  width:12rem;
  height:2.5rem;
  color: white;
  background-color: #2c3e50;
  border-color: #9DAAF2;
  border-width: 3px;
  border-radius: 3px;
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  margin-left: auto;
  margin-right: auto;
}
.user-gen{
  box-sizing: border-box;
  width:70vw;
  margin:0px;
  height:90%;
  border-radius: 15px;
}
.mobile-navi{
  display:none;
}
.mobile{
  display: none;
}
.Generations-textarea{
  width:100%;
  height:100%;
  position: relative;
  box-sizing: border-box;
  border-radius: 15px;
}
.txtarea{
  width:100%;
  height:100%;
  box-sizing: border-box;
  resize:none;
  padding:1rem;
  background-color: #ecf0f1;
  color:rgb(71, 71, 71);
  
}
.Generations-container{
  width:100%;
  height:100%;
 box-sizing: border-box;
}
@media screen and (max-width: 850px) {
  .laptop,h3{
    display: none;
  }
  .user-content{
    display: flex;
    flex-direction: column;
  }
  .mobile{
    display: block;
  }
  .user-nav{
    width:100%;
    height:auto;
    padding: 0.2rem;
    box-sizing: border-box;
  }
  .user-gen{
    width:100%;
    box-sizing: border-box;
  }
  .mobile-navi{
    display:flex;
    flex-wrap: wrap;
    width:100%;
    height:auto;
    align-items: center;
    justify-content: space-evenly;
  }
  .mbutton{
    width:8rem;
    gap:10px;
    height:2rem;
    margin:0.5rem;
    color:white;
    background-color: transparent;
    border-color: #9DAAF2;
    border-width: 4px;
    border-radius: 5px;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
  }
  
}