.Navigations-container{
    
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
    

}

.basic-form-modal{
    
    width:100%;
    height:70vh;
    z-index:999;
    top:15px;
    left:20px;
}
.copyrightm{
    margin-top:0px;
    margin-bottom:0px;
    text-align: center;
    color:white;
}
.Navi-title{
    font-family: "Poppins", sans-serif;
    color:rgb(56, 228, 176);
    text-decoration: underline dashed rgb(219, 219, 109);
    font-size: 25px;
}
h3{
    text-align: center;
    color: white;
    font-family: Roboto,Verdana;
}
.Navi-IT{
    background-color: rgb(236, 24, 95);
    padding:0.4rem;
    border-radius: 3px;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    
}
.Navi-basic{
    width:15rem;
    height:2.5rem;
    color: white;
    background-color: #2c3e50;
    border-color: #9DAAF2;
    border-width: 3px;
    border-radius: 3px;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
}

.Navi-advanced{
    width:15rem;
    height:2.5rem;
    color:white;
    background-color: #2c3e50;
    border-color: #9DAAF2;
    border-width: 3px;
    border-radius: 3px;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
}
.Navi-generate{
    display: flex;
    flex-direction: row;
    gap:10px;
}

.Navi-clear-btn{
    width:8rem;
    height:2rem;
    color:white;
    background-color: transparent;
    border-color: #9DAAF2;
    border-width: 2px;
    border-radius: 3px;
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
}