

.copy{
    position: absolute;
    top:4px;
    right:10px;
    color: white;
    background-color: rgb(236, 5, 82);
    border-radius: 2px;
    width:3rem;
    height:2rem;
}
.txtarea{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-color: transparent;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}